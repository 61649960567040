import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';
import UserApiService from "@/services/user.service";


const securedRoute = (to, from, next) => {
  if (!store.state.staffAuth.token) {
    next({ name: 'login' });
    return
  } 
  next();
    return
}


const publicRoute = (to, from, next) => {
  if (store.state.staffAuth.token) {
    next({ to: '/' });
    return
  } 
  next();
    return
}

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    beforeEnter: securedRoute,
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    beforeEnter: publicRoute,
    meta: { auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});


router.beforeEach( async (to, from, next) => {
  if(store.state.staffAuth.user){
    await UserApiService.staffProfile().then(
      (res) => {
        localStorage.setItem('user', JSON.stringify(res));
      },
      (error) => {
        console.log(error);
      }
    );
  }
  window.scrollTo(0, 0); // reset scroll position to top of page
  next();
  return
});

export default router;
